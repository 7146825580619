import React, { Component } from "react";
import { Row, Col, Modal } from "antd";
import MapWrap from "../../components/common/MapWrap";
import RangeDateFilter from "../../components/RangeDateFilter";
import HorizontalBarChart from "../../components/charts/HorizontalBarChart";
import PieChart from "../../components/charts/PieChartWrap";
import VerticalBarChart from "../../components/charts/VerticalBarChart";
import Title from "components/common/Title";
//import DtpFourBarChart from "modules/operational/containers/DtpFourBarChart";
import moment from "moment";
import request from "../../utils/request";
import CalculateDtp from "modules/dtp/CalculateDtp";
import DtpTable from "modules/dtp/DtpTable";
import LoadingCss from "../../components/LoadingCss";
import LoadingSpinner from "../../components/LoadingSpinner";
import Table from "../../components/Table";
import { CHART_HEIGHT } from "../../components/common/Constants";
import RowStat from "components/RowStat";
import { Container } from "components/common/Elements";
import ExportExcel from "components/ExportExcel";

let FORMAT = "YYYY-MM-DD HH:mm";

function formatCrimeDate(value) {
  return value ? moment(value).format(FORMAT) : "";
}

class Dtp extends Component {
  constructor() {
    super();
    this.state = {
      startDate: moment().subtract(1, "y").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      payload: {},
      loading: true,
      flatData: [],
      modalVisible: false,
      selected: {},
      records: [],
      selectedSlice: [],
      maxDate: null,
    };
    this.setDate = this.setDate.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { startDate, endDate } = this.state;
    const dtp = request(`/dtp/dtpoper?dateb=${startDate}&datee=${endDate}`);
    let dateFrom = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    let dateTo = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");

    const dtpByMonthPrev = request(
      `/dtp/dtpoper?dateb=${dateFrom}&datee=${dateTo}`
    );
    const dtpByMonth = request(
      `/dtp/dtpoper?dateb=${moment()
        .startOf("month")
        .format("YYYY-MM-DD")}&datee=${today}`
    );

    dtpByMonthPrev.then((res) => {
      let mapObjects = res.districts.map((item) => {
        return [...item.data];
      });
      mapObjects = mapObjects.flat();
      mapObjects = mapObjects.filter((i) => i.date);
      let temp = [];

      mapObjects.forEach((obj) => {
        let index = temp.findIndex((i) => i.category === obj.narushenie);
        let tempoObj = temp[index];
        index !== -1
          ? (temp[index] = { ...tempoObj, value: tempoObj.value + 1 })
          : temp.push({
              category: obj.narushenie,
              value: 1,
            });
      });

      this.setState((prev) => ({
        ...prev,
        payload: {
          ...prev.payload,
          catPieChart: temp,
        },
      }));
    });

    dtpByMonth.then((res) => {
      let mapObjects = res.districts.map((item) => {
        return [...item.data];
      });
      mapObjects = mapObjects.flat();
      mapObjects = mapObjects.filter((i) => i.date);
      let temp = [];

      mapObjects.forEach((obj) => {
        let index = temp.findIndex((i) => i.category === obj.narushenie);
        let tempoObj = temp[index];
        index !== -1
          ? (temp[index] = { ...tempoObj, value: tempoObj.value + 1 })
          : temp.push({
              category: obj.narushenie,
              value: 1,
            });
      });

      this.setState((prev) => ({
        ...prev,
        payload: {
          ...prev.payload,
          catPieChart2: temp,
        },
      }));
    });

    dtp
      .then((r) => {
        let payload = CalculateDtp(r);
        let flatData = [];
        r.districts.forEach((d) => {
          d.data.forEach((item) => flatData.push(item));
        });
        let maxDate = moment(
          Math.max.apply(
            null,
            flatData.map((e) => new Date(e.date))
          )
        );
        this.setState((prev) => ({
          ...prev,
          payload: {
            ...prev.payload,
            ...payload,
          },
          flatData,
          loading: false,
          constantData: r,
          maxDate,
        }));
      })
      .catch((err) => {});
  };

  setDate(payload) {
    this.setState(
      {
        [payload.dateType]: payload.value,
        selectedSlice: [],
      },
      () => this.getData()
    );
  }

  handleSelect(obj) {
    this.setState({ selected: obj });
  }

  showIncision = (category) => {
    const { constantData } = this.state;
    let selectedSlice;
    constantData.districts.forEach((element) => {
      if (element.name === category) selectedSlice = element.data;
    });
    let uniqIncision = [...new Set(selectedSlice.map((el) => el.incision))];
    uniqIncision = uniqIncision.map((el) => {
      return {
        category: el,
        value: 0,
      };
    });
    uniqIncision.forEach((el, index) => {
      selectedSlice.forEach((slice) => {
        if (el.category === slice.incision) uniqIncision[index].value++;
      });
    });
    this.setState({ selectedSlice: uniqIncision });
  };

  showModal = (category, key) => {
    const { flatData } = this.state;
    let records = flatData.filter((el) => el[key] === category);
    if (!records[0]) records = flatData.filter((el) => !el[key]);
    this.setState({
      records,
      modalVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false,
      records: [],
    });
  };

  showMonthData = (category) => {
    const { flatData } = this.state;
    let records = flatData.filter((el) => {
      let date = moment(el.date).format("MMMM");
      if (date === category) return true;
      else return false;
    });
    this.setState({
      records,
      modalVisible: true,
    });
  };
  handleOk = (e) => {
    this.setState({
      filtersVisible: false,
    });
  };

  handleCancelModal = (e) => {
    this.setState({
      filtersVisible: false,
    });
  };
  showFilters = () => {
    const state = !this.state.filtersVisible;
    this.setState({
      filtersVisible: state,
    });
  };

  render() {
    const {
      selected,
      startDate,
      endDate,
      loading,
      payload: {
        mapObjects,
        rayons,
        catViolation,
        catPieChart,
        catPieChart2,
        timeDay,
        weatherCondition,
      },
    } = this.state;
    let {
      t,
      isMobile,
      i18n: { language },
    } = this.props;
    let tableRows = [
      { name: t("publicSafety.dtp.violation"), key: "narushenie" },
      { name: t("publicSafety.dtp.town"), key: "gorod" },
      { name: t("publicSafety.dtp.date"), key: (o) => formatCrimeDate(o.date) },
      { name: t("publicSafety.dtp.lighting"), key: "lights" },
      {
        name: t("publicSafety.dtp.driverCondition"),
        key: "sostoyanieVoditelya",
      },
      { name: t("publicSafety.dtp.culprit"), key: "vinovnik" },
      { name: t("publicSafety.dtp.timesOfDay"), key: "vremyaSutok" },
    ];

    let public_trans = 0,
      pedestrian = 0,
      alcohol = 0;
    mapObjects &&
      mapObjects.forEach((item) => {
        if (item.vidDTP === 1) pedestrian++;
        else if (item.tipTransporta === "1") public_trans++;
        else if (item.sostoyanieVoditelya === "алкогольное опьянение")
          alcohol++;
      });
    let statsDtp = [
      {
        name: t("publicSafety.dtp.total"),
        value: mapObjects && mapObjects.length,
      },
      {
        name: t("publicSafety.dtp.involvingPublicTransport"),
        value: public_trans,
      },
      {
        name: t("publicSafety.dtp.withTheParticipationOfPedestrian"),
        value: pedestrian,
      },
      { name: t("publicSafety.dtp.intoxicated"), value: alcohol },
    ];
    let mapData =
      mapObjects &&
      mapObjects.map((el) => ({
        ...el,
        lat: el.x,
        lon: el.y,
      }));

    const mappedTimeDay = timeDay?.map((day) => {
      if (language === "ru") return day;
      else if (language === "kz") {
        let word;
        switch (day.category) {
          case "сумерки":
            word = t("publicSafety.dtp.dusk");
            break;
          case "день":
            word = t("publicSafety.dtp.day");
            break;
          case "ночь":
            word = t("publicSafety.dtp.night");
            break;
          case null:
            word = null;
            break;
          default:
            break;
        }

        return {
          ...day,
          category: word,
        };
      }
    });
    return (
      <div>
        <Row className="mb20" gutter={16}>
          <Col span={isMobile ? 24 : 6}>
            <RangeDateFilter
              width={"100"}
              startDate={startDate}
              endDate={endDate}
              setDate={this.setDate}
            />
          </Col>
          <ExportExcel
            filename={`ДТП за период ${startDate} - ${endDate}`}
            data={this.state.flatData}
            fields={[
              {
                title: "Район",
                dataIndex: "district",
              },
              {
                title: "Категория",
                dataIndex: "category",
              },
              {
                title: "Дата",
                dataIndex: "date",
              },
              {
                title: "Свет",
                dataIndex: "lights",
              },
              {
                title: "Место",
                dataIndex: "mesto",
              },
              {
                title: "Нарушение",
                dataIndex: "narushenie",
              },
              {
                title: "Состояние водителя",
                dataIndex: "sostoyanieVoditelya",
              },
              {
                title: "Виновник",
                dataIndex: "vinovnik",
              },
              {
                title: "Время суток",
                dataIndex: "vremyaSutok",
              },
            ]}
          />
        </Row>
        <Container>
          <Row>
            <Col span={24}>
              <Row type="flex" style={{ marginBottom: 20 }}>
                {statsDtp.map((stat) => (
                  <RowStat
                    key={stat.name}
                    label={stat.name}
                    count={stat.value}
                  />
                ))}
              </Row>
            </Col>
          </Row>

          <Row className={"mb20"} type="flex">
            <Col span={isMobile ? 24 : 16}>
              <div style={{ position: "relative" }}>
                {loading && <LoadingCss />}
                <MapWrap
                  objects={mapData || []}
                  handleSelect={(obj) => this.handleSelect(obj)}
                  handleMultipleSelect={(obj) => this.handleSelect(obj)}
                  height={"410px"}
                />
              </div>
            </Col>
            <Col span={isMobile ? 24 : 8}>
              <DtpTable
                title={t("publicSafety.dtp.accidentCard") + ":"}
                object={selected}
                tableRows={tableRows}
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col span={isMobile ? 24 : 12}>
              <Title>{t("publicSafety.dtp.violationCategory")}</Title>
              <LoadingSpinner
                loading={loading}
                className="flex-full flex layout-centered"
              >
                <HorizontalBarChart
                  advancedScroll
                  advancedScrollEnd={0.5}
                  id="dtpTypeChart"
                  data={catViolation}
                  color="#EB5757"
                  responsive={true}
                  height={CHART_HEIGHT}
                />
              </LoadingSpinner>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <Title>{t("publicSafety.dtp.areas")}</Title>
              <LoadingSpinner
                loading={loading}
                className="flex-full flex layout-centered"
              >
                <HorizontalBarChart
                  advancedScroll
                  advancedScrollEnd={0.5}
                  id="rayonchart"
                  data={rayons}
                  // height={CHART_HEIGHT}
                  noCellSize
                  responsive={true}
                  color="#34C792"
                  height="40vh"
                  handleClick={(category) => this.showIncision(category)}
                />
              </LoadingSpinner>
            </Col>
          </Row>
        </Container>
        <Row className={"mt20"}>
          <Col span={isMobile ? 24 : 8}>
            <Title>
              {t("publicSafety.dtp.comparitiveAnalysisOfRoadAccidentsByMonth")}
            </Title>
            <Row gutter={12}>
              <Col span={12}>
                <div style={pieWrapperStyle}>
                  <PieChart
                    data={catPieChart}
                    id="piechartDtp"
                    hideLabels={true}
                    showLegend={false}
                    height={"35vh"}
                  />
                </div>
                <div className="dtpp1" style={{ marginLeft: "60px" }}>
                  {t("publicSafety.dtp.numberOfAccidents")}
                  {t(`month.${moment().subtract(1, "month").format("MMMM")}`)}
                </div>
              </Col>
              <Col span={12}>
                <div style={pieWrapperStyle}>
                  <PieChart
                    data={catPieChart2}
                    id="piechartDtp2"
                    showLegend={false}
                    hideLabels={true}
                    height={"35vh"}
                    responsive={true}
                  />
                </div>
                <div className="dtpp2" style={{ marginLeft: "60px" }}>
                  {t("publicSafety.dtp.numberOfAccidents")}
                  {t(`month.${moment().format("MMMM")}`)}
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={isMobile ? 24 : 8}>
            <Title>{t("publicSafety.dtp.weather")}</Title>
            <VerticalBarChart
              data={weatherCondition}
              id="weatherChart"
              handleClick={(category) => {
                this.showModal(category, "usloviya");
              }}
              rota
              color="#EB5757"
              noCellSize
              height={CHART_HEIGHT}
              labelWidth={125}
              rotateLabels={-47}
              responsive={true}
            />
          </Col>
          <Col span={isMobile ? 24 : 8}>
            <Title>{t("publicSafety.dtp.timesOfDay")}</Title>
            <VerticalBarChart
              id="timeChart"
              color="#458FD8"
              handleClick={(category) => {
                this.showModal(category, "vremyaSutok");
              }}
              height={CHART_HEIGHT}
              noCellSize
              data={mappedTimeDay}
              labelWidth={100}
              responsive={true}
            />
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <a
            href="http://infopublic.pravstat.kz/dtp/"
            style={{ padding: "0px 10px 10px 0" }}
          >
            Источник информации
          </a>
        </Row>
        <Modal
          title="ДАННЫЕ О ЗАРЕГИСТРИРОВАННЫХ ДТП"
          className="appeals_modal"
          visible={this.state.modalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width={1200}
          bodyStyle={{
            height: 400,
          }}
        >
          <div className="table_height">
            {this.state.records.length > 0 && (
              <Table
                height="75vh"
                headers={[
                  { label: "Район", key: "district" },
                  { label: "Разрезность", key: "incision" },
                  { label: "Категория нарушения", key: "narushenie" },
                  { label: "Погодные условия", key: "usloviya" },
                  { label: "Время суток", key: "vremyaSutok" },
                  { label: "Освещение", key: "lights" },
                  { label: "Состояние водителя", key: "sostoyanieVoditelya" },
                  { label: "Дата нарушения", key: "date" },
                ]}
                objects={this.state.records}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default Dtp;

let pieWrapperStyle = {};
