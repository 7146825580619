export const transformTime = (value) => {
  if (value > 60) {
    const hours = Math.floor(value / 60);
    const minutes = value - hours * 60;

    if (hours && minutes) {
      return `${hours} ч ${minutes} м`;
    }

    if (hours) {
      return `${hours} ч`;
    }

    return null;
  }

  return typeof value === "number" && value ? `${value} м` : null;
};
