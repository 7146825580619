import React, { Component } from "react";
import { Table, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";

class RoadTable extends Component {
  state = {
    pageSize: 7,
    currentPage: 1,
    totalElements: 0,
    loading: true,
    roads: [],
  };

  componentDidUpdate(oldProps) {
    if (JSON.stringify(this.props.roads) !== JSON.stringify(oldProps.roads)) {
      this.setState({
        roads: this.props.roads,
        loading: false,
      });
    }
  }

  pageChanged = (val) => {
    this.setState({
      currentPage: val,
    });
  };

  openMgz = (record) => {
    console.log(record);
  };

  render() {
    let { t } = this.props;
    const Columns = [
      {
        title: "МГЗ",
        key: "mgz",
        width: 40,
        render: (text, record) => (
          <Button
            shape="circle"
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            className="active-btn"
            color="#000"
            onClick={() => this.openMgz(record)}
          />
        ),
      },
      {
        title: t("infrastructure.repairAndConstruction.street"),
        dataIndex: "street",
        key: "street",
        width: 200,
      },
      {
        title: t("infrastructure.repairAndConstruction.plot"),
        dataIndex: "site",
        key: "site",
        width: 200,
      },
      {
        title: t("infrastructure.repairAndConstruction.category"),
        dataIndex: "workCategory",
        key: "workCategory",
        width: 200,
      },
      {
        title: t("infrastructure.repairAndConstruction.responsible"),
        dataIndex: "eventName",
        key: "eventName",
        width: 200,
      },
      {
        title: t("infrastructure.repairAndConstruction.contractor"),
        dataIndex: "builder",
        key: "builder",
        width: 200,
      },
    ];

    const config = {
      loading: this.state.loading,
      columns: Columns,
      dataSource: this.state.roads,
      showHeader: true,
      className: "mt20 dark_table",
      pagination: {
        position: "bottom",
        current: this.state.currentPage,
        onChange: this.pageChanged,
        pageSize: this.state.pageSize,
        total: this.state.roads.length, // Necessary
      },

      // scroll: {
      //   y: 500,
      // },
    };
    let { isMobile } = this.props;
    return (
      <div className="mt20">
        <Table
          rowKey={(record) => record.id}
          {...config}
          scroll={{ x: isMobile ? 800 : 0 }}
        />
      </div>
    );
  }
}

export default withTranslation()(RoadTable);
