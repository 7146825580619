import React from "react";
import { Select } from "antd";

import styles from "./index.module.scss";
const Option = Select.Option;

export default ({
  options,
  filterField,
  handleChange,
  handleClear,
  placeholder,
  style = {},
  minWidth = 150,
  maxWidth = 400,
  width = "100%",
  ...props
}) => {
  let defaultValue = {};
  if (props.value) {
    defaultValue = { value: props.value };
  }

  return (
    <Select
      className={styles.item}
      showSearch
      style={{ minWidth: minWidth, width: width, ...style }}
      optionFilterProp="children"
      onChange={(value) => {
        if (value) {
          handleChange({ [filterField]: value });
        } else {
          handleClear(filterField);
        }
      }}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      placeholder={placeholder}
      {...defaultValue}
      {...props}
    >
      {options === undefined
        ? null
        : options.map((option, i) => (
            <Option key={i} value={option.value}>
              {option.label}
            </Option>
          ))}
    </Select>
  );
};
