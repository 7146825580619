import React from "react";
import { Table } from "antd";
import { transformTime } from "utils/transformTime";
import moment from "moment";
import styles from "./TableComponent.module.scss";

const columns = [
  {
    title: "Перевозчик",
    dataIndex: "transporters",
    render: (field) =>
      field.map((item) => (
        <div key={item}>
          <span>{item}</span>
          <br />
        </div>
      )),
  },
  {
    title: "Населенный пункт (откуда)",
    width: "15%",
    dataIndex: "destinationLocality",
  },
  {
    title: "Населенный пункт (куда)",
    width: "15%",
    dataIndex: "arrivalLocality",
  },
  {
    title: "Время отправления",
    dataIndex: "departureTimeFromStart",
    render: (field) => field && <span>{moment(field).format("HH:mm")}</span>,
  },
  {
    title: "Время прибытия",
    dataIndex: "departureTimeFromEnd",
    render: (field) => field && <span>{moment(field).format("HH:mm")}</span>,
  },
  {
    title: "Время движения",
    dataIndex: "movementTime",
    render: (field) => transformTime(field),
  },
  {
    title: "Длина пути",
    dataIndex: "distance",
    render: (field) => field && <span>{`${field} км`}</span>,
  },
];

export const TableComponent = ({ tableData }) => (
  <div className={styles.box}>
    <Table
      className={styles.table}
      rowKey={(_, index) => index}
      dataSource={tableData.filteredData}
      columns={columns}
      pagination={false}
      size="small"
      scroll={{ y: 230 }}
    />
  </div>
);
