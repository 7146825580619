import instance from "services/api";

export const getRoutes = (params) => {
  return instance(false)
    .get(`/infobus/getRoutes?${params}`)
    .then((res) => res?.data || [])
    .catch((err, data) => {
      // return handleError(err);
    });
};

export const getPaymentTypes = () => {
  return instance(false)
    .get(`/infobus/getPaymentTypes`)
    .then((res) => res?.data || [])
    .catch((err, data) => {
      // return handleError(err);
    });
};

export const getYears = () => {
  return instance(false)
    .get(`/infobus/getYears`)
    .then((res) => res?.data || [])
    .catch((err, data) => {
      // return handleError(err);
    });
};

export const getTransactionByDay = (params) => {
  return instance(false)
    .get(`/infobus/getTransactionByDay?${params}`)
    .then((res) => res?.data || [])
    .catch((err, data) => {
      // return handleError(err);
    });
};

export const getTransactionByType = (params) => {
  return instance(false)
    .get(`/infobus/getTransactionByType?${params}`)
    .then((res) => res?.data || [])
    .catch((err, data) => {
      // return handleError(err);
    });
};

export const getTransactionByRoute = (params) => {
  return instance(false)
    .get(`/infobus/getTransactionByRoute?${params}`)
    .then((res) => res?.data || [])
    .catch((err, data) => {
      // return handleError(err);
    });
};

export const getTransactionByMonth = (params) => {
  return instance(false)
    .get(`/infobus/getTransactionByMonth?${params}`)
    .then((res) => res?.data || [])
    .catch((err, data) => {
      // return handleError(err);
    });
};

export const getSentimentResourceStats = (params) => {
  return instance(false)
    .get(`/alem/sentimentResourceStats?${params}`)
    .then((res) => res?.data || [])
    .catch((err, data) => {
      // return handleError(err);
    });
};
