import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "./pages/Login/Login";
import { connect } from "react-redux";
import CrimeData from "./pages/Operational/CrimeData";
import Dtp from "./pages/Operational/Dtp";
import Damumed from "./pages/DamuMed";
import MainLayout from "./components/layout/MainLayout";
import RoadConstruction from "./pages/RoadConstruction";
import Kindergarden from "./pages/PreSchoolEducation/KindergardenQueue";
import AnalyticalIndicators from "./pages/AnalyticalIndicators";
import School from "pages/School";
import Business from "pages/Economy/Business";
import Income from "pages/Economy/Income";
import Outcome from "pages/Economy/Outcome";
import MainPage from "pages/MainPage/MainPage";
// import CameraMap from "pages/CameraMap";
import Error from "pages/Error/Error";
import PrivateRoute from "./utils/PrivateRoute";

import { redirect } from "redux/redirect";
import { withTranslation } from "react-i18next";
import bcrypt from "bcryptjs";
// import MonitoringSmi from "pages/MonitoringSmi";
import Infobus from "pages/Infobus";
import { RouteSchedule } from "pages/RouteSchedule/RouteSchedule";
// Указываем пути именно так, без начального `./`, иначе webpack не найдёт файлы.

class App extends Component {
  state = {
    currentUser: {},
    casesRoles: [],
  };
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.redirectTo) {
      this.props.history.push(nextProps.redirectTo);
      this.props.onRedirect();
    }
  }

  checkRoute() {
    let check = false;
    let checkModules = localStorage.getItem("modules") !== null ? true : false;
    if (checkModules)
      JSON.parse(localStorage.getItem("modules")).forEach((module) => {
        if (bcrypt.compareSync(this.props.location.pathname, module))
          check = true;
      });
    return checkModules ? check : false;
  }

  render() {
    let { t } = this.props;
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/error"
          component={(item) => <MainLayout component={Error} />}
        />
        <PrivateRoute
          exact
          path="/dtp"
          component={(item) => (
            <MainLayout
              title={t("caseNames.roadTrafficAccidents")}
              component={Dtp}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/ddo"
          component={(item) => (
            <MainLayout
              title={t("caseNames.distributionOfChildrenInKindergartens")}
              component={Kindergarden}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/crime"
          component={(item) => (
            <MainLayout
              title={t("caseNames.criminalSituation")}
              component={CrimeData}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/road-construction"
          component={(item) => (
            <MainLayout
              title={t("caseNames.repairAndConstruction")}
              component={RoadConstruction}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/school"
          component={(item) => (
            <MainLayout title={t("caseNames.school")} component={School} />
          )}
          checkRole={this.checkRoute()}
        />
        <Route
          exact
          path="/"
          component={(item) => (
            <MainLayout title={t("mainPage.main")} component={MainPage} />
          )}
        />
        <PrivateRoute
          exact
          path="/damu-med"
          component={(item) => (
            <MainLayout
              title={t("caseNames.morbidityData")}
              component={Damumed}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/analytical-indicators"
          component={(item) => (
            <MainLayout
              title="Аналитические индикаторы"
              component={AnalyticalIndicators}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/business-map"
          component={(item) => (
            <MainLayout
              title={t("caseNames.businessCard")}
              component={Business}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/income"
          component={(item) => (
            <MainLayout title={t("caseNames.income")} component={Income} />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/outcome"
          component={(item) => (
            <MainLayout title={t("caseNames.costs")} component={Outcome} />
          )}
          checkRole={this.checkRoute()}
        />
        {/* <PrivateRoute
          exact
          path="/cameramap"
          component={(item) => (
            <MainLayout title={"Карта камер"} component={CameraMap} />
          )}
          checkRole={this.checkRoute()}
        /> */}
        {/* <Route
          exact
          path="/monitoring-smi"
          component={(item) => (
            <MainLayout
              title={t("caseNames.monitoringSmi")}
              component={MonitoringSmi}
            />
          )}
          checkRole={this.checkRoute()}
        /> */}
        <Route
          exact
          path="/infobus"
          component={(item) => (
            <MainLayout title={t("caseNames.infobus")} component={Infobus} />
          )}
          checkRole={this.checkRoute()}
        />
        <Route
          exact
          path="/route-schedule"
          component={() => (
            <MainLayout
              title={t("caseNames.routeSchedule")}
              component={RouteSchedule}
            />
          )}
          checkRole={this.checkRoute()}
        />
        {/* {lazyRouteMap.map(([route, importPath]) => (
          <Route
            exact
            key={importPath}
            path={route}
            render={(props) => (
              <MainLayout
                {...props}
                component={lazy(() => import("./" + importPath))}
              />
            )}
          />
        ))} */}
      </Switch>
    );
  }
}

export default withTranslation()(
  withRouter(
    connect(
      (state) => ({
        redirectTo: state.redirect.redirectTo,
      }),
      (dispatch) => ({
        onRedirect: () => dispatch(redirect()),
      })
    )(App)
  )
);
