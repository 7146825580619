import _ from "lodash";

// Это не трогаем, для разработки переопределяем путь у себя
// локально в .env.development.local, либо переменными окружения.
// const BASE_ADDRESS = process.env.REACT_APP_BASE_ADDRESS || "";

// Пути разруливаются на сервере через проксирование. Каждая "подсистема"
// использует свой префикс, чтобы не создавать 100500 редиректов и не занимать
// много портов.
const def = {
  // Integration: BASE_ADDRESS + "/integration",
  //Api: BASE_ADDRESS + "/backend",
  // Api: "https://akmsc.smartkokshetau.kz/api/akm",
  Api: "https://akmsc.smartkokshetau.kz/api/akm",
  CcAdmin: "https://akmsc.smartkokshetau.kz/api/ccadmin",
  Auth: "https://akmsc.smartkokshetau.kz/api/auth",
  // CubeJs: BASE_ADDRESS + "/cubejs",
  // Damu: BASE_ADDRESS + "/api",
};

const t = (str) => _.trimEnd(str, "/");

export const BaseAddress = {
  Api: t(process.env.szREACT_APP_API_ROOT || def.Api),
  Osm: t(process.env.REACT_APP_OSM_TILE_ROOT || def.Osm),
  // Video: t(process.env.REACT_APP_VIDEO_ROOT || def.Video),
  // CubeJs: t(process.env.REACT_APP_CUBE_JS_ROOT || def.CubeJs),
  CcAdmin: t(process.env.REACT_APP_CCADMIN_ROOT || def.CcAdmin),
  Auth: t(process.env.REACT_APP_AUTH_ROOT || def.Auth),
  // Integration: t(process.env.REACT_APP_INTEGRATION_ROOT || def.Integration),
  // Damu: t(process.env.REACT_APP_DAMU_INTEGRATION || def.Damu),
};
