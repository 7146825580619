import React, { Component } from "react";
import { YMaps, Map, ZoomControl, FullscreenControl } from "react-yandex-maps";

import { AkmolaCoords } from "../../constants";
import { withTranslation } from "react-i18next";

const repairProgress = [
  { status: "запланированный ремонт", color: "#ff0000" },
  { status: "в работе", color: "#ffff00" },
  { status: "выполнено", color: "#008000" },
  { status: null, color: "#000" },
];
class RoadMap extends Component {
  state = {
    loading: true,
    center: [51.960768, 69.914122],
    zoom: 7,
    roads: [],
    oldLanguage: {},
  };

  onApiAvaliable(ymaps) {
    this.ymaps = ymaps;
  }

  setMapControlInstanceRef = (ref) => {
    this.map = ref;
    setTimeout(() => {
      this.setState({ loading: false });
      this.applyAkmolaPolygon();
    }, 3000);
  };

  applyAkmolaPolygon = () => {
    const akmolaPolygon = new this.ymaps.GeoObject(
      {
        geometry: {
          type: "LineString",
          coordinates: AkmolaCoords,
        },
        properties: {
          balloonContent: "LineString",
        },
      },
      {
        strokeColor: "#000000",
        strokeWidth: 3,
      }
    );

    if (this.map) {
      this.map.geoObjects.add(akmolaPolygon);
    }
  };

  componentDidUpdate(oldProps) {
    const { oldLanguage } = this.state;
    let {
      t,
      i18n: { language },
    } = this.props;
    if (JSON.stringify(oldProps.roads) !== JSON.stringify(this.props.roads)) {
      this.setState({ roads: this.props.roads }, () => {
        if (this.map && this.ymaps) {
          this.addRoads();
        }
      });
    }
    if (this.map && this.ymaps) {
      if (oldLanguage !== language) {
        this.addRoads();
        this.setState({ oldLanguage: language });
      }
      this.applyAkmolaPolygon();
    }
  }

  parseMapCoords(coords) {
    return coords.map((item) => {
      return [item.x, item.y];
    });
  }

  returnBalloonContent = (road) => {
    let { t } = this.props;
    let openRoad = road.roadwayIsOpened ? "Да" : "Нет";
    let closeRoad = road.streetIsBlocked ? "Да" : "Нет";
    return (
      `<b>${t("infrastructure.repairAndConstruction.categoryOfWork")}:</b>` +
      road.workCategory +
      "<br/>" +
      `<b>${t("infrastructure.repairAndConstruction.street")}:</b> ` +
      road.street +
      "<br/>" +
      `<b>${t("infrastructure.repairAndConstruction.plot")}:</b> ` +
      road.site +
      "<br/>" +
      `<b>${t("infrastructure.repairAndConstruction.responsible")}:</b> ` +
      road.eventName +
      "<br/>" +
      `<b>${t("infrastructure.repairAndConstruction.contractor")}:</b> ` +
      road.builder +
      "<br/>" +
      `<b>${t(
        "infrastructure.repairAndConstruction.contractorContacts"
      )}:</b> ` +
      road.builderContacts +
      "<br/>" +
      `<b>${t("infrastructure.repairAndConstruction.warrantyPeriod")}:</b> ` +
      road.warrantyPeriod +
      "<br/>" +
      `<b>${t("infrastructure.repairAndConstruction.start")}:</b> ` +
      road.workplanStart +
      "<br/>" +
      `<b>${t("infrastructure.repairAndConstruction.ending")}:</b> ` +
      road.workplanEnd +
      "<br/>" +
      `<b>${t(
        "infrastructure.repairAndConstruction.openingOfTheRoadway"
      )}:</b> ` +
      openRoad +
      "<br/>" +
      `<b>${t(
        "infrastructure.repairAndConstruction.overlappingTheStreet"
      )}:</b> ` +
      closeRoad +
      "<br/>" +
      `<b>${t("infrastructure.repairAndConstruction.comments")}:</b> ` +
      road.comment +
      "<br/>" +
      `<b>${t("infrastructure.repairAndConstruction.comments")}(млн.тг):</b> ` +
      (road.projectCost ? road.projectCost : "") +
      "<br/>" +
      `<b>${t(
        "infrastructure.repairAndConstruction.amountFor2021"
      )} (млн.тг):</b> ` +
      (road.amountPlanned ? road.amountPlanned : "") +
      "<br/>" +
      `<b>${t("infrastructure.repairAndConstruction.length")}:</b> ` +
      (road.length ? road.length : "") +
      "<br/>" +
      `<b>${t(
        "infrastructure.repairAndConstruction.whoAcceptedTheWork"
      )}:</b> ` +
      (road.acceptedWorkPerson ? road.acceptedWorkPerson : "") +
      "<br/>" +
      "<b>ID:</b> " +
      road.id
    );
  };

  addRoads = () => {
    const { roads } = this.state;
    this.map.geoObjects.removeAll();
    for (let index = 0; index < roads.length; index++) {
      let road = roads[index];
      let coords = this.parseMapCoords(road.coords);
      let balloonContent = this.returnBalloonContent(road);
      let roadColor;
      repairProgress.map((el) =>
        road.repairProgress === el.status ? (roadColor = el.color) : null
      );
      let geometry = {
        type: "LineString",
        coordinates: coords,
      };
      if (road.objectType === 2) {
        geometry.coordinates = geometry.coordinates[0];
        geometry.type = "Point";
      } else if (road.objectType === 3) {
        let arr = [];
        arr.push(geometry.coordinates);
        geometry.coordinates = arr;
        geometry.type = "Polygon";
      }
      let myGeoObject = new this.ymaps.GeoObject(
        {
          geometry,
          properties: {
            balloonContent,
          },
        },
        {
          draggable: false,
          strokeColor: roadColor,
          strokeWidth: 4,
          opacity: 0.6,
        }
      );
      if (this.map) {
        this.map.geoObjects.add(myGeoObject);
      }
    }
    this.applyAkmolaPolygon();
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        {/* {this.state.loading && <Loading />} */}
        <YMaps onApiAvaliable={(ymaps) => this.onApiAvaliable(ymaps)}>
          <Map
            state={{
              center: this.state.center,
              zoom: this.state.zoom,
              controls: [],
            }}
            width="100%"
            height="450px"
            instanceRef={this.setMapControlInstanceRef}
          >
            <ZoomControl
              options={{
                size: "small",
                zoomDuration: 1000,
              }}
            />
            <FullscreenControl />
          </Map>
        </YMaps>
      </div>
    );
  }
}

export default withTranslation()(RoadMap);
