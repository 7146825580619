import React, { Component } from "react";
import { Row, Col } from "antd";
import RoadFilters from "./RoadFilters";
import RoadMap from "./RoadMap";
import MapLegend from "./MapLegend";
import RoadTable from "./RoadTable";
import requestAdmin from "./../../utils/requestAdmin";
import { Container } from "components/common/Elements";

const dateFormat = "DD-MM-YYYY";
class RoadConstruction extends Component {
  state = {
    filter: {
      workCategory: "Средний ремонт дорог",
    },
    constantData: [],
    filteredRoads: [],
    showCross: false,
    startDate: null,
    endDate: null,
  };

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 3000);
  }

  getData = () => {
    requestAdmin("/api/v1/roadway/get/0/999").then((r) => {
      this.setState(
        {
          constantData: r.content,
          filteredRoads: r.content,
        },
        () => this.applyFilters()
      );
    });
  };

  changeShowCross = (e) => {
    this.setState({ showCross: e.target.checked });
  };

  changeFilter = (obj, type) => {
    let filter = {};
    if (type === "add") {
      filter = { ...this.state.filter, ...obj };
    } else {
      filter = Object.assign({}, this.state.filter);
      delete filter[obj];
    }
    this.setState({ filter }, () => {
      if (Object.keys(filter).length) this.applyFilters();
      else this.setState({ filteredRoads: [] });
    });
  };

  applyFilters = () => {
    const { filter, constantData } = this.state;
    let arr = [];
    let arrWithFilter = constantData;
    Object.keys(filter).forEach((key) => {
      arrWithFilter = arrWithFilter.filter((e) => e[key] === filter[key]);
    });

    this.setState({ filteredRoads: arrWithFilter });
  };

  setDate = ([start, end]) => {
    this.setState(
      {
        startDate: start.format(dateFormat),
        endDate: end.format(dateFormat),
      },
      () => this.applyFilters()
    );
  };

  render() {
    let { isMobile } = this.props;
    return (
      <>
        <RoadFilters
          isMobile={isMobile}
          filter={this.state.filter}
          showCross={this.state.showCross}
          changeShowCross={this.changeShowCross}
          changeFilter={this.changeFilter}
          dateFormat={dateFormat}
          setDate={this.setDate}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
        />
        <Container>
          <Row>
            <Col span={isMobile ? 24 : 21}>
              <RoadMap roads={this.state.filteredRoads} />
            </Col>
            <Col span={isMobile ? 24 : 3}>
              <MapLegend />
            </Col>
          </Row>
        </Container>
        <RoadTable roads={this.state.filteredRoads} isMobile={isMobile} />
      </>
    );
  }
}

export default RoadConstruction;
