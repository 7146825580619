import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import _ from "lodash";

const CHART_LOCALE = "ru";

export class StackedBarChart extends Component {
  state = {
    data: [],
  };

  fallbackId = _.uniqueId("stacked-bar-chart");

  get id() {
    return this.props.id || this.fallbackId;
  }

  sortData = (data, reverse = false) => {
    if (!Array.isArray(data) || !data.length) {
      return;
    }
    const keys = this.props.stack.map((x) => x.field);
    data.sort((x, y) => {
      let sumX = 0,
        sumY = 0;
      for (const key of keys) {
        sumX += Number(x[key]) || 0;
        sumY += Number(y[key]) || 0;
      }
      return reverse ? sumY - sumX : sumX - sumY;
    });
  };

  componentDidMount() {
    const {
      handleClick,
      legendPosition = "top",
      colorsArr,
      labelWidth = 150,
      truncate,
      calculatePercent,
    } = this.props;

    const chart = am4core.create(this.id, am4charts.XYChart);
    this.chart = chart;

    chart.language.locale = CHART_LOCALE;
    const data = this.props.data;
    if (!this.props.hideLegend) {
      chart.legend = new am4charts.Legend();
      chart.legend.position = legendPosition;
    }
    if (truncate) {
      chart.legend.labels.template.truncate = true;
      chart.legend.labels.template.maxWidth = 100;
    }
    if (this.props.sort) {
      this.sortData(data, this.props.sort === "reverse");
    }
    chart.data = data;

    const units = this.props.units;

    if (this.props.panel) {
      chart.legend.fontSize = 12;
      const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 15;
      markerTemplate.height = 15;
    }

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    const category = this.props.category || "category";
    categoryAxis.dataFields.category = category;
    categoryAxis.renderer.grid.template.location = 1;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.maxWidth = labelWidth;
    categoryAxis.renderer.labels.template.truncate = true;
    categoryAxis.renderer.labels.template.tooltipText = "{category}";
    chart.xAxes.push(new am4charts.ValueAxis());
    const label = categoryAxis.renderer.labels.template;
    label.align = "left";
    if (colorsArr) {
      chart.colors.list = colorsArr.map((c) => am4core.color(c));
    }

    const createSeries = ({ field, name, color, hidden }) => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = category;
      series.stacked = true;
      series.calculatePercent = calculatePercent;
      series.name = name;

      series.columns.template.tooltipText = calculatePercent
        ? "[font-size:14px]{name}: [bold]{valueX.percent.formatNumber('#.0')}%"
        : `[font-size:14px]{name}: [bold]{valueX} ${units || ""}`;

      if (colorsArr && colorsArr.length) {
        series.colors = new am4core.ColorSet();
        series.colors.list = colorsArr.map((color) => am4core.color(color));
      } else {
        series.columns.template.stroke = am4core.color(color);
        series.columns.template.fill = am4core.color(color);
      }

      const labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = 0.5;

      labelBullet.label.text = calculatePercent
        ? "{valueX.percent.formatNumber('#.0')}%"
        : "{valueX}";
      labelBullet.label.fontSize = 11;
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.label.hideOversized = true;
      labelBullet.label.adapter.add("text", (text, target) =>
        target.dataItem.valueX ? text : ""
      );

      if (handleClick) {
        series.columns.template.events.on("down", (ev) => {
          const category =
            ev.target.dataItem.dataContext[this.props.category || "category"];
          const context = ev.target.dataItem.dataContext;
          handleClick(category, context);
        });
      }

      series.hidden = hidden;
    };

    if (this.props.alignMiddle) {
      chart.numberFormatter.numberFormat = "#.#s";
      this.updateAxisRange(data);
    }

    const { advancedScroll, customize } = this.props;

    if (customize) {
      customize({ chart });
    }

    this.props.stack.forEach(createSeries);

    if (advancedScroll) {
      const { advancedScrollStart } = this.props;
      let { advancedScrollEnd } = this.props;
      if (this.props.advancedScrollItems) {
        advancedScrollEnd =
          1 - this.props.advancedScrollItems / (this.props.data.length || 1);
      }
      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.start = advancedScrollStart ? advancedScrollStart : 1;
      chart.scrollbarY.end = advancedScrollEnd ? advancedScrollEnd : 0.7;
      chart.scrollbarY.parent = chart.rightAxesContainer;
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  componentDidUpdate(oldProps) {
    if (JSON.stringify(oldProps.data) !== JSON.stringify(this.props.data)) {
      const data = this.props.data;
      if (this.props.sort && data) {
        this.sortData(data, this.props.sort === "reverse");
      }
      if (this.props.alignMiddle) {
        this.updateAxisRange(data);
      }
      if (this.props.advancedScroll) {
        const { scrollbarY } = this.chart;
        if (this.props.advancedScrollItems) {
          scrollbarY.end =
            this.props.advancedScrollItems / (this.props.data.length || 1);
        } else {
          scrollbarY.end = this.props.advancedScrollEnd
            ? this.props.advancedScrollEnd
            : 0.3;
        }
      }
      this.chart.data = data;
    }
  }

  updateAxisRange = (data) => {
    let maxValue = 0;
    const fields = this.props.stack.map((s) => s.field);

    for (const dataRow of data) {
      for (const field of fields) {
        const abs = Math.abs(dataRow[field]);
        if (abs > maxValue) {
          maxValue = abs;
        }
      }
    }
    if (maxValue > 500) {
      maxValue += 100;
    }

    const [valueAxis] = this.chart.xAxes;

    valueAxis.min = -maxValue;
    valueAxis.max = maxValue;
  };

  render() {
    const { height = "50vh" } = this.props;
    return (
      <div className="horizontal_chart_wrap">
        <div id={this.id} style={{ height: height }} />
      </div>
    );
  }
}
