import React, { useEffect, useState } from "react";
import { Select, InputNumber, TimePicker } from "antd";
import styles from "./SelectList.module.scss";
import moment from "moment";
import { useMemo, useCallback } from "react";
import { ExportExcel } from "pages/RouteSchedule/ExportExcel";

const { Option } = Select;

const timeFormat = "HH:mm";

export const SelectList = ({
  transportersData,
  setTransportersData,
  settlementsData,
  setSettlementsData,
  tableData,
  setTableData,
}) => {
  const [filters, setFilters] = useState({
    transportersFilter: [],
    settlementsFilterFrom: [],
    settlementsFilterTo: [],
    distanceFilterFrom: null,
    distanceFilterTo: null,
    timePickerFilterFrom: null,
    timePickerFilterTo: null,
  });

  const onChangeTransporterSelect = useCallback((value) => {
    setFilters((prev) => ({
      ...prev,
      transportersFilter: value,
    }));
  }, []);

  const onSearchTransporterSelect = useCallback(
    (value) => {
      const result = transportersData.data.filter((item) =>
        item.transporter.toLowerCase().includes(value.toLowerCase())
      );
      setTransportersData((prev) => ({
        ...prev,
        searchData: result,
      }));
    },
    [setTransportersData, transportersData]
  );

  const clearTransporterSelect = useCallback(() => {
    setTransportersData((prev) => ({
      ...prev,
      searchData: prev.data,
    }));
  }, [setTransportersData]);

  const onChangeSettlementSelectFrom = useCallback((value) => {
    setFilters((prev) => ({
      ...prev,
      settlementsFilterFrom: value,
    }));
  }, []);

  const onSearchSettlementSelect = useCallback(
    (value) => {
      const result = settlementsData.data.filter((item) =>
        item.settlements.toLowerCase().includes(value.toLowerCase())
      );
      setSettlementsData((prev) => ({
        ...prev,
        searchData: result,
      }));
    },
    [setSettlementsData, settlementsData]
  );

  const onChangeSettlementSelectTo = useCallback((value) => {
    setFilters((prev) => ({
      ...prev,
      settlementsFilterTo: value,
    }));
  }, []);

  const clearSettlementSelect = useCallback(() => {
    setSettlementsData((prev) => ({
      ...prev,
      searchData: prev.data,
    }));
  }, [setSettlementsData]);

  const listSettingsSelect = useMemo(
    () => [
      {
        id: 1,
        placeholder: "Перевозчик",
        onChange: onChangeTransporterSelect,
        onSearch: onSearchTransporterSelect,
        onBlur: clearTransporterSelect,
        onDeselect: clearTransporterSelect,
        options: transportersData.searchData.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.transporter}
          </Option>
        )),
      },
      {
        id: 2,
        placeholder: "Населенный пункт(откуда)",
        onChange: onChangeSettlementSelectFrom,
        onSearch: onSearchSettlementSelect,
        onBlur: clearSettlementSelect,
        onDeselect: clearSettlementSelect,
        options: settlementsData.searchData.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.settlements}
          </Option>
        )),
      },
      {
        id: 3,
        placeholder: "Населенный пункт(куда)",
        onChange: onChangeSettlementSelectTo,
        onSearch: onSearchSettlementSelect,
        onBlur: clearSettlementSelect,
        onDeselect: clearSettlementSelect,
        options: settlementsData.searchData.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.settlements}
          </Option>
        )),
      },
    ],
    [
      clearSettlementSelect,
      clearTransporterSelect,
      onChangeSettlementSelectFrom,
      onChangeSettlementSelectTo,
      onChangeTransporterSelect,
      onSearchSettlementSelect,
      onSearchTransporterSelect,
      settlementsData.searchData,
      transportersData.searchData,
    ]
  );

  const onChangeDistanceFrom = (value) => {
    setFilters((prev) => ({
      ...prev,
      distanceFilterFrom: value ? value : null,
    }));
  };

  const onChangeDistanceTo = (value) => {
    setFilters((prev) => ({
      ...prev,
      distanceFilterTo: value ? value : null,
    }));
  };

  const onChangeTimePickerFrom = (value) => {
    setFilters((prev) => ({
      ...prev,
      timePickerFilterFrom: value ? value : null,
    }));
  };

  const onChangeTimePickerTo = (value) => {
    setFilters((prev) => ({
      ...prev,
      timePickerFilterTo: value ? value : null,
    }));
  };

  const getValueFiltered = useCallback(
    (tableData) => {
      let result = [...tableData];
      if (filters.transportersFilter.length) {
        result = result.filter((item) => {
          const transportersIds = item.transporters.map(
            (transporterName) =>
              transportersData.data.find(
                (transporterObj) =>
                  transporterObj.transporter === transporterName
              ).id
          );
          return filters.transportersFilter.find((id) =>
            transportersIds.includes(id)
          );
        });
      }
      if (filters.settlementsFilterFrom.length) {
        result = result.filter((item) => {
          const destinationLocalityId = settlementsData.data.find(
            (elem) => elem.settlements === item.destinationLocality
          ).id;
          return filters.settlementsFilterFrom.includes(destinationLocalityId);
        });
      }
      if (filters.settlementsFilterTo.length) {
        result = result.filter((item) => {
          const destinationLocalityId = settlementsData.data.find(
            (elem) => elem.settlements === item.arrivalLocality
          ).id;
          return filters.settlementsFilterTo.includes(destinationLocalityId);
        });
      }
      if (filters.distanceFilterFrom) {
        result = result.filter(
          (item) => filters.distanceFilterFrom <= item.distance
        );
      }
      if (filters.distanceFilterTo) {
        result = result.filter(
          (item) => filters.distanceFilterTo >= item.distance
        );
      }
      if (filters.timePickerFilterFrom) {
        result = result.filter(
          (item) =>
            filters.timePickerFilterFrom.format(timeFormat) ===
            moment(item.departureTimeFromStart).format(timeFormat)
        );
      }
      if (filters.timePickerFilterTo) {
        result = result.filter(
          (item) =>
            filters.timePickerFilterEnd.format(timeFormat) ===
            moment(item.departureTimeFromStart).format(timeFormat)
        );
      }

      return result;
    },
    [filters, settlementsData, transportersData]
  );

  useEffect(() => {
    setTableData((prev) => ({
      ...prev,
      filteredData: getValueFiltered(prev.data),
    }));
  }, [filters, getValueFiltered, setTableData]);

  return (
    <div className={styles.box}>
      {listSettingsSelect.map((select) => (
        <Select
          className={styles.select}
          key={select.id}
          placeholder={select.placeholder}
          onChange={select.onChange}
          onSearch={select.onSearch}
          onBlur={select.onBlur}
          onDeselect={select.onDeselect}
          dropdownMatchSelectWidth={false}
          filterOption={false}
          mode="multiple"
          allowClear
        >
          {select.options}
        </Select>
      ))}
      <div className={styles.wrap}>
        <InputNumber
          className={styles.inputNumber}
          placeholder="Расстояние от"
          onChange={onChangeDistanceFrom}
        />
        <InputNumber
          className={styles.inputNumber}
          placeholder="Расстояние до"
          onChange={onChangeDistanceTo}
        />
      </div>
      <div className={styles.wrap}>
        <TimePicker
          format={timeFormat}
          onChange={onChangeTimePickerFrom}
          placeholder="Время отправления"
          showNow={false}
        />
        <TimePicker
          format={timeFormat}
          onChange={onChangeTimePickerTo}
          placeholder="Время прибытия"
          showNow={false}
        />
      </div>
      <div className={styles.wrapper}>
        <ExportExcel
          fileName="Расписание маршрутов"
          data={tableData.filteredData}
        />
      </div>
    </div>
  );
};
