import React from "react";
import { Row, Col, Modal, Button, Switch, Table } from "antd";
import FilterSelect from "../../components/FilterSelect";
import request from "../../utils/request";
import CalculatePreSchool from "modules/Social/CalculatePreSchool";
import PieChartWrap from "../../components/charts/PieChartWrap";
import TableComponent from "../../components/Table";
import MapWrap from "../../components/common/MapWrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import HorizontalBarChart from "../../components/charts/HorizontalBarChart";
import { formatNumber } from "../../utils/helpers";
import LoadingCss from "../../components/LoadingCss";
import { Container, OrangeBox } from "components/common/Elements";
import styled from "styled-components";
import { SaveOutlined } from "@ant-design/icons";

class Ddo extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      data: {
        selectFilters: {},
        totalArea: 0,
        totalSeats: 0,
        totalContingent: 0,
        pieStat: {},
        data: [],
        tableData: [],
      },
      langType: true,
      records: [],
      visible: false,
      filterValues: {},
      selectFilters: {},
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    request("/datastub/ddo").then((r) => {
      const data = CalculatePreSchool(r);

      let selectFilters = {
        status: [...new Set(r.map((el) => el.status))],
        studyLang: [...new Set(r.map((el) => el.studyLang))],
        district: [...new Set(r.map((el) => el.district))],
        name: [...new Set(r.map((el) => el.name))],
        form: [...new Set(r.map((el) => el.form))],
        type: [...new Set(r.map((el) => el.type))],
        time: [...new Set(r.map((el) => el.time))],
        localityType: [...new Set(r.map((el) => el.localityType))],
      };
      selectFilters.time = selectFilters.time.filter((t) => t);
      Object.keys(selectFilters).forEach((key) => {
        selectFilters[key] = selectFilters[key].map((el) => {
          return {
            label: el,
            value: el.toLowerCase(),
          };
        });
      });
      setTimeout(() => {
        this.setState({
          constantData: r,
          data,
          selectFilters,
          loading: false,
        });
      }, 500);
    });
  };

  handleSelect(val) {
    let { filterValues } = this.state;
    let obj = { ...filterValues, ...val };
    this.setState({ filterValues: obj, loading: true }, () =>
      this.applySelectFilter()
    );
  }

  applySelectFilter() {
    const { constantData, filterValues } = this.state;
    let arr = [];
    constantData.forEach((element) => {
      let count = 0;
      Object.keys(filterValues).forEach((key) => {
        if (filterValues[key] === element[key].toLowerCase()) count++;
      });
      if (count === Object.keys(filterValues).length) arr.push(element);
    });
    const data = CalculatePreSchool(arr);

    setTimeout(() => {
      this.setState({
        data,
        loading: false,
      });
    }, 500);
  }

  handlMapSelect(obj) {
    let arrObj = [obj];
    this.setState({
      visible: true,
      records: arrObj.map((e) => (e["key"] = e["id"])),
    });
  }
  handleMultipleSelect(obj) {
    this.setState({
      visible: true,
      records: obj,
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      records: [],
    });
  };

  showModal = (key, value) => {
    let records = [],
      arr = [];
    const { constantData, filterValues } = this.state;
    constantData.forEach((element) => {
      let count = 0;
      Object.keys(filterValues).forEach((key) => {
        if (filterValues[key] === element[key].toLowerCase()) count++;
      });
      if (count === Object.keys(filterValues).length) arr.push(element);
    });
    arr.forEach((el) => {
      if (el[key] === value) records.push(el);
    });
    this.setState({
      visible: true,
      records,
    });
  };

  handleClear = (type) => {
    let { filterValues } = this.state;
    delete filterValues[type];
    this.setState({ filterValues, loading: true }, () =>
      this.applySelectFilter()
    );
  };
  showFilters = () => {
    this.setState((prev) => ({
      filtersVisible: !prev.filtersVisible,
    }));
  };
  langTypeChanger = () => {
    this.setState({
      langType: !this.state.langType,
    });
  };

  handleLangMappedTableDataHead(value) {
    let {
      t,
      i18n: { language },
    } = this.props.kinderProps.props;
    return value?.map((category) => {
      if (language === "ru") return category;
      else if (language === "kz") {
        let word = "";
        switch (category.key) {
          case "name":
            word = t("education.childrenKindergartens.name");
            break;
          case "address":
            word = t("education.childrenKindergartens.address");
            break;
          case "district":
            word = t("education.childrenKindergartens.area");
            break;
          case "studyLang":
            word = t("education.childrenKindergartens.languageOfInstruction");
            break;
          case "form":
            word = t("education.childrenKindergartens.formOfInstitutions");
            break;
          case "seats":
            word = t("education.childrenKindergartens.projectCapacity");
            break;
          case "kontingent":
            word = t("education.childrenKindergartens.actualFullness");
            break;
          case "status":
            word = t("education.childrenKindergartens.fullness");
            break;
          case "type":
            word = t("education.childrenKindergartens.DDOType");
            break;
          case "time":
            word = t("education.childrenKindergartens.durationOfStay");
            break;
          default:
            break;
        }

        return {
          ...category,
          label: word,
        };
      }
    });
  }

  handleLangSelectedPieChart(value) {
    let {
      t,
      i18n: { language },
    } = this.props.kinderProps.props;
    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "В очереди":
            word = t("education.childrenKindergartens.queue");
            break;
          case "Выдано направлений":
            word = t("education.childrenKindergartens.issuedDirections");
            break;
          case "Зачислено":
            word = t("education.childrenKindergartens.enrolled");
            break;

          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }

  handleLangPieLang(value) {
    let {
      t,
      i18n: { language },
    } = this.props.kinderProps.props;
    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "смешанный":
            word = t("education.childrenKindergartens.queue");
            break;
          case "казахский":
            word = t("education.childrenKindergartens.kazakh");
            break;
          case "русский":
            word = t("education.childrenKindergartens.russian");
            break;

          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }

  handleLangTimePieChart(value) {
    let {
      t,
      i18n: { language },
    } = this.props.kinderProps.props;
    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "с полным днем пребывания":
            word = t("education.childrenKindergartens.fullDayOfStay");
            break;
          case "с неполным днем пребывания":
            word = t("education.childrenKindergartens.partTime");
            break;
          case "с круглосуточным пребыванием":
            word = t("education.childrenKindergartens.withTwentyFourStay");
            break;

          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }

  handleLangPieData(value) {
    let {
      t,
      i18n: { language },
    } = this.props.kinderProps.props;
    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "Профицит":
            word = t("education.childrenKindergartens.surplus");
            break;
          case "Норма":
            word = t("education.childrenKindergartens.norm");
            break;
          case "Дефицит":
            word = t("education.childrenKindergartens.deficit");
            break;

          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }
  handleLangTypePieChart(value) {
    let {
      t,
      i18n: { language },
    } = this.props.kinderProps.props;

    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "санаторный ясли-сад":
            word = t("education.childrenKindergartens.sanatoriumNurseryGarden");
            break;
          case "ясли-сад":
            word = t("education.childrenKindergartens.nurseryGarden");
            break;
          case "детский сад":
            word = t("education.childrenKindergartens.kindergarten");
            break;
          case "дошкольный мини-центр":
            word = t("education.childrenKindergartens.preschoolMiniCenter");
            break;

          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }
  //

  render() {
    const { data, loading, selectFilters, records, visible } = this.state;
    const { inQueue, selected } = this.props.kinderProps.state;
    let {
      isMobile,
      t,
      i18n: { language },
    } = this.props.kinderProps.props;
    const tableData = [
      {
        title: t("education.childrenKindergartens.name"),
        dataIndex: "name",
      },
      {
        title: t("education.childrenKindergartens.address"),
        dataIndex: "address",
      },
      {
        title: t("education.childrenKindergartens.district"),
        dataIndex: "district",
      },
      {
        title: t("education.childrenKindergartens.languageOfInstruction"),
        dataIndex: "studyLang",
      },
      {
        title: t("education.childrenKindergartens.formOfInstitution"),
        dataIndex: "form",
      },
      {
        title: t("education.childrenKindergartens.designCapacity"),
        dataIndex: "seats",
      },
      {
        title: t("education.childrenKindergartens.actualFullness"),
        dataIndex: "kontingent",
      },
      {
        title: t("education.childrenKindergartens.fullness"),
        dataIndex: "status",
      },
      {
        title: t("education.childrenKindergartens.DDOtype"),
        dataIndex: "type",
      },
      {
        title: t("education.childrenKindergartens.durationOfStay"),
        dataIndex: "time",
      },
    ];

    let ddoitems = [
      {
        title: t(
          "education.childrenKindergartens.numberOfPreschoolOrganizations"
        ),
        value: formatNumber(data.data.length),
      },
      {
        title: t("education.childrenKindergartens.projectCapacity"),
        value: formatNumber(data.totalSeats),
      },
      {
        title: t("education.childrenKindergartens.actualFullness"),
        value: formatNumber(data.totalContingent),
      },
      {
        title: t("education.childrenKindergartens.deviation"),
        value: formatNumber((data.totalContingent - data.totalSeats) * -1),
      },
      {
        title: t("education.childrenKindergartens.queue"),
        value: formatNumber((inQueue && inQueue.length) || 0),
      },
      {
        title: t("education.childrenKindergartens.issuedDirections"),
        value: formatNumber(
          this.props.kinderProps.state.selected.direction || 0
        ),
      },
      {
        title: t("education.childrenKindergartens.enrolled"),
        value: formatNumber(
          this.props.kinderProps.state.selected.enrolled || 0
        ),
      },
    ];
    let { filtersVisible } = this.state;
    let mappedTableDataHead = this.handleLangMappedTableDataHead(
      data.tableData.head
    );
    let mappedSelectedPieChart = this.handleLangSelectedPieChart(
      selected.pieChart
    );
    let mappedPieLang = this.handleLangPieLang(data.pieLang);
    let mappedTimePieChart = this.handleLangTimePieChart(data.timePieChart);
    let mappedPieData = this.handleLangPieData(data.pieData);
    let mappedTypePieChart = this.handleLangTypePieChart(data.typePieChart);

    return (
      <React.Fragment>
        <Row>
          <Button
            type="primary"
            onClick={this.showFilters}
            icon={<SaveOutlined />}
          >
            {t("education.childrenKindergartens.openFilter")}
          </Button>
        </Row>
        <Container style={{ backgroundColor: "#1A1B21" }}>
          <Row gutter={16}>
            <Col span={isMobile ? 24 : 8}>
              {ddoitems.map((item) => (
                <DdoItem>
                  <div>{item.title}</div>
                  <OrangeBox>{item.value}</OrangeBox>
                </DdoItem>
              ))}
            </Col>
            <Col span={isMobile ? 24 : 9}>
              {loading && <LoadingCss />}
              <MapWrap
                height="450px"
                objects={data.data}
                handleSelect={(obj) => this.handlMapSelect(obj)}
                handleMultipleSelect={(obj) => this.handleMultipleSelect(obj)}
              />
            </Col>
            <Col span={isMobile ? 24 : 7}>
              <h3 align="center">
                {t("education.childrenKindergartens.fullnessStructure")}
              </h3>
              <LoadingSpinner
                loading={loading}
                className="flex-full flex layout-centered"
              >
                <PieChartWrap
                  responsive
                  key={mappedPieData}
                  height="450px"
                  hideLabels
                  colorsArr={["#01b8aa", "#fbc02d", "#fd625e"]}
                  showLegend
                  handleClick={(category) => {
                    this.showModal("status", category);
                  }}
                  data={mappedPieData}
                  id="preSchoolPie1"
                />
              </LoadingSpinner>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col span={24}>
              <h3 align="center">
                {t("education.childrenKindergartens.fullnessOfDDO")}
              </h3>
              <LoadingSpinner
                loading={loading}
                className="flex-full flex layout-centered"
              >
                <HorizontalBarChart
                  responsive
                  data={data.barChart || []}
                  id="preSchoolDataChart"
                  height="500px"
                  advancedScroll
                  advancedScrollStart={0.99}
                  advancedScrollEnd={1}
                  twoSeries
                  colorsArr={["#01b8aa", "#fbc02d"]}
                  noCellSize
                  name={t("education.childrenKindergartens.projectCapacity")}
                  name1={t("education.childrenKindergartens.actualFullness")}
                  showLegend
                />
              </LoadingSpinner>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col span={isMobile ? 24 : 8} style={{ textAlign: "center" }}>
              <h3 style={{ display: "inline-block" }}>
                {t("education.childrenKindergartens.DDOType")}
              </h3>
              <Switch
                defaultChecked
                style={{ margin: "0 auto", textAlign: "center" }}
                onChange={this.langTypeChanger}
              />
              <h3 style={{ display: "inline-block" }}>
                {t("education.childrenKindergartens.languageOfInstruction")}
              </h3>
              {this.state.langType ? (
                <LoadingSpinner
                  loading={loading}
                  className="flex-full flex layout-centered"
                >
                  <PieChartWrap
                    responsive
                    key={mappedPieLang || []}
                    height="45vh"
                    hideLabels
                    colorsArr={["#01b8aa", "#fbc02d", "#fd625e"]}
                    handleClick={(category) => {
                      this.showModal("languageEducation", category);
                    }}
                    showLegend
                    data={mappedPieLang || []}
                    id="schoolLang"
                  />
                </LoadingSpinner>
              ) : (
                <LoadingSpinner
                  loading={loading}
                  className="flex-full flex layout-centered"
                >
                  <HorizontalBarChart
                    responsive
                    data={mappedTypePieChart || []}
                    id="typePieId"
                    sort
                    height="45vh"
                    colorsArr={[
                      "#0288d1",
                      "#2da57e",
                      "#ffb399",
                      "#ff33ff",
                      "#ffff99",
                      "#00b3e6",
                    ]}
                  />
                </LoadingSpinner>
              )}
            </Col>
            <Col span={isMobile ? 24 : 8}>
              <h3 align="center">
                {t("education.childrenKindergartens.durationOfStay")}
              </h3>
              <LoadingSpinner
                loading={loading}
                className="flex-full flex layout-centered"
              >
                <PieChartWrap
                  responsive
                  key={mappedTimePieChart}
                  height="45vh"
                  hideLabels
                  // colorsArr={["#828282", "#00b3e6", "#fd625e", "#fbc02d"]}
                  colorsArr={["#EB5757", "#01b8aa", "#6CEB57"]}
                  handleClick={(category) => {
                    this.showModal("time", category);
                  }}
                  showLegend
                  data={mappedTimePieChart || []}
                  id="timePieId"
                />
              </LoadingSpinner>
            </Col>

            <Col span={isMobile ? 24 : 8}>
              <h3 align="center">
                {t("education.childrenKindergartens.queueStatus")}
              </h3>
              <LoadingSpinner
                loading={loading}
                className="flex-full flex layout-centered"
              >
                <PieChartWrap
                  responsive
                  data={mappedSelectedPieChart || []}
                  id="kindergardenId"
                  // colorsArr={["#828282", "#00b3e6", "#fd625e", "#fbc02d"]}
                  colorsArr={["#EB5757", "#01b8aa", "#5F6CDF"]}
                  key={
                    mappedSelectedPieChart ? mappedSelectedPieChart.length : 0
                  }
                  showLegend
                  height="40vh"
                  hideLabels
                />
              </LoadingSpinner>
              <Modal
                title="Школы"
                visible={filtersVisible}
                onOk={this.showFilters}
                onCancel={this.showFilters}
                footer={null}
                width={1200}
              >
                <Row gutter={16}>
                  <Col span={isMobile ? 24 : 6}>
                    <FilterSelect
                      options={selectFilters.district || []}
                      handleChange={this.handleSelect}
                      placeholder="Район"
                      filterField="district"
                      handleClear={this.handleClear}
                      allowClear
                    />
                  </Col>
                  <Col span={isMobile ? 24 : 6}>
                    <FilterSelect
                      options={selectFilters.localityType || []}
                      handleChange={this.handleSelect}
                      placeholder="Местность"
                      filterField="localityType"
                      handleClear={this.handleClear}
                      allowClear
                    />
                  </Col>
                  <Col span={isMobile ? 24 : 6}>
                    <FilterSelect
                      options={selectFilters.name || []}
                      handleChange={this.handleSelect}
                      placeholder="Наименование"
                      filterField="name"
                      handleClear={this.handleClear}
                      allowClear
                      width="98%"
                    />
                  </Col>
                  <Col span={isMobile ? 24 : 6}>
                    <FilterSelect
                      options={selectFilters.status || []}
                      handleChange={this.handleSelect}
                      placeholder="Наполненность"
                      filterField="status"
                      handleClear={this.handleClear}
                      allowClear
                    />
                  </Col>
                  <Col span={isMobile ? 24 : 6}>
                    <FilterSelect
                      options={selectFilters.studyLang || []}
                      handleChange={this.handleSelect}
                      placeholder={t(
                        "education.childrenKindergartens.languageOfInstruction"
                      )}
                      filterField="studyLang"
                      handleClear={this.handleClear}
                      allowClear
                    />
                  </Col>
                  <Col span={isMobile ? 24 : 6}>
                    <FilterSelect
                      options={selectFilters.form || []}
                      handleChange={this.handleSelect}
                      placeholder="Форма собственности"
                      filterField="form"
                      handleClear={this.handleClear}
                      allowClear
                    />
                  </Col>
                  <Col span={isMobile ? 24 : 6}>
                    <FilterSelect
                      options={selectFilters.type || []}
                      handleChange={this.handleSelect}
                      placeholder="Вид ДДО"
                      filterField="type"
                      handleClear={this.handleClear}
                      allowClear
                    />
                  </Col>
                  <Col span={isMobile ? 24 : 6}>
                    <FilterSelect
                      options={selectFilters.time || []}
                      handleChange={this.handleSelect}
                      placeholder="Время пребывания"
                      filterField="time"
                      handleClear={this.handleClear}
                      allowClear
                    />
                  </Col>
                </Row>
              </Modal>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col span={isMobile ? 24 : 24}>
            <h3 align="center">
              {t("education.childrenKindergartens.freePlaces")}
            </h3>
            <LoadingSpinner
              loading={loading}
              className="flex-full flex layout-centered"
            >
              <HorizontalBarChart
                data={selected.horizontalChart || []}
                id="queueId"
                sort
                colorsArr={["#0288d1", "#2da57e"]}
                noCellSize
                responsive
                height={"40vh"}
                key={
                  selected.horizontalChart ? selected.horizontalChart.length : 0
                }
              />
            </LoadingSpinner>
          </Col>
          {/* <Col span={isMobile ? 24 : 8}>
            <h3 align="center">Способ подачи заявки на постановку в ДДО</h3>
            <LoadingSpinner
              loading={loading}
              className="flex-full flex layout-centered"
            >
              <VerticalBarChart
                data={selected.verticalChart || []}
                id="kindergardenRayonsId"
                key={selected.verticalChart ? selected.verticalChart.length : 0}
                name="Встали в очередь через «Балабакша»"
                name1="Встали в очередь через egov"
                height="40vh"
                colorsArr={[
                  "#0288d1",
                  "#2da57e",
                  "#ffb399",
                  "#ff33ff",
                  "#ffff99",
                  "#00b3e6",
                ]}
                rotateLabels
                showLegend
                wrapLabel
                labelWidth={80}
                noCellSize
                twoSeries
                responsive
              />
            </LoadingSpinner>
          </Col> */}
        </Row>
        <div>
          <h3 align="center">
            {t("education.childrenKindergartens.pivotTable")}
          </h3>
          <LoadingSpinner
            loading={loading}
            className="flex-full flex layout-centered"
          >
            <TableComponent
              height="45vh"
              headers={mappedTableDataHead || []}
              objects={data.tableData.objects}
            />
          </LoadingSpinner>
        </div>

        <Modal
          title="Дошкольные организации"
          className="ddo_modal"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width={5000}
          height={1000}
        >
          {records.length > 0 && (
            <Table
              pagination={false}
              columns={tableData || []}
              dataSource={records}
              scroll={{ x: 200, y: 600 }}
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}
export default Ddo;

export let DdoItem = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 10px 5px;
  &:last-child {
    margin-bottom: 0px;
  }
`;
