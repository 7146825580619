import { Button } from "antd";
import React from "react";
import * as XlsxPopulate from "xlsx-populate";
import moment from "moment";
import { transformTime } from "utils/transformTime";

const combineDataToXls = (data, chartType) => {
  let xlsData = [];
  data.forEach((item) => {
    xlsData.push([
      item.transporters && item.transporters.join(", "),
      item.destinationLocality,
      item.arrivalLocality,
      item.departureTimeFromStart &&
        moment(item.departureTimeFromStart).format("HH:mm"),
      item.departureTimeFromEnd &&
        moment(item.departureTimeFromEnd).format("HH:mm"),
      item.movementTime && transformTime(item.movementTime),
      item.distance && `${item.distance} км`,
    ]);
  });
  return xlsData;
};

export const ExportExcel = ({ fileName = "excel", data }) => {
  function getWorkbook() {
    return new Promise(function (resolve, reject) {
      let req = new XMLHttpRequest();
      let url = `/xlsx/routeSchedule.xlsx`;
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
      req.onreadystatechange = function () {
        if (req.readyState === 4) {
          if (req.status === 200) {
            resolve(XlsxPopulate.fromDataAsync(req.response));
          } else {
            reject("Received a " + req.status + " HTTP code.");
          }
        }
      };

      req.send();
    });
  }

  function generate(type) {
    return getWorkbook().then(function (workbook) {
      workbook.sheet(0).cell("A6").value(combineDataToXls(data)).style({
        border: "Borders",
        borderStyle: "thin",
        fontFamily: "Times New Roman",
        fontSize: 12,
        wrapText: true,
      });

      return workbook.outputAsync({ type: type });
    });
  }

  function generateBlob() {
    return generate()
      .then(function (blob) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName + ".xlsx");
        } else {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = fileName + ".xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch(function (err) {
        alert(err.message || err);
        throw err;
      });
  }

  const exportToExcel = () => {
    generateBlob();
  };

  return (
    <Button onClick={exportToExcel} style={{ marginLeft: "auto" }}>
      Экспорт в Excel
    </Button>
  );
};
